import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import { usePathname, useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';

import { NAV } from './config-layout';
import { navConfig } from './config-navigation';
import { adminNavConfig } from './config-navigation';

import { Collapse, Divider, ListItemIcon, ListItemText } from '@mui/material';
import Iconify from 'src/components/iconify';
import AccountPopover from './common/account-popover';
import appContext from 'src/Context/AppContext';
import { auth } from 'src/config/firebase';

// ----------------------------------------------------------------------

export default function Nav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const AppContext = useContext(appContext);
  const router = useRouter();

  const upLg = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);


  const renderMenu = (
    <Box justifyContent={'space-between'} display={'flex'} flexDirection={'column'} height={"100%"}>
      <Stack component="nav" spacing={0.5} sx={{ px: 2 }} >
        {AppContext?.user?.role === 'user' ?
          navConfig.map((item) => (
            <NavItem key={item.title} item={item} />
          )) :
          adminNavConfig.map((item) => (
            <NavItem key={item.title} item={item} />
          ))
        }
      </Stack>
      <Stack component="nav" spacing={0.5} sx={{ px: 2, py: 2 }} >
        <ListItemButton
          component={RouterLink}
          sx={{
            minHeight: 44,
            borderRadius: 0.75,
            typography: 'body2',
            color: 'text.secondary',
            textTransform: 'capitalize',
            fontWeight: 'fontWeightMedium',
            ...(pathname === 'help' && {
              color: 'primary.main',
              fontWeight: 'fontWeightSemiBold',
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
              },
            }),
          }}
        >
          <AccountPopover />
        </ListItemButton>
        {AppContext?.user?.role === 'user' ? <ListItemButton
          component={RouterLink}
          href={'/help'}
          sx={{
            minHeight: 44,
            borderRadius: 0.75,
            typography: 'body2',
            color: 'text.secondary',
            textTransform: 'capitalize',
            fontWeight: 'fontWeightMedium',
            ...(pathname === '/help' && {
              color: 'primary.main',
              fontWeight: 'fontWeightSemiBold',
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
              },
            }),
          }}
        >
          <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
            <Iconify icon="eva:question-mark-circle-outline" width={25} />
          </Box>

          <Box component="span">Help </Box>
        </ListItemButton> :
          <ListItemButton
            component={RouterLink}
            href={'/'}
            onClick={() => {
              localStorage.clear();
              router.push('/login');
              auth.signOut();
              window.location.reload();
            }}
            sx={{
              minHeight: 44,
              borderRadius: 0.75,
              typography: 'body2',
              color: 'text.secondary',
              textTransform: 'capitalize',
              fontWeight: 'fontWeightMedium',
              ...(true && {
                color: 'error.main',
                fontWeight: 'fontWeightSemiBold',
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
                },
              }),
            }}
          >
            <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
              <Iconify icon="eva:log-out-outline" width={25} />
            </Box>

            <Box component="span">Logout </Box>
          </ListItemButton>}
      </Stack>

    </Box>
  );

  const renderUpgrade = (
    <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
      <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
        <Box
          component="img"
          src="/assets/illustrations/illustration_avatar.png"
          sx={{ width: 100, position: 'absolute', top: -50 }}
        />

        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">Get more?</Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
            From only $69
          </Typography>
        </Box>

        <Button
          href="https://material-ui.com/store/items/minimal-dashboard/"
          target="_blank"
          variant="contained"
          color="inherit"
        >
          Upgrade to Pro
        </Button>
      </Stack>
    </Box>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 2, ml: 4, mb: 0 }} />

      {/* {renderAccount} */}
      <Divider sx={{ my: 2 }} />
      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />
      {/* 
      {renderUpgrade} */}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
        background: "#ffffff"
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>

      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const pathname = usePathname();
  const [open, setOpen] = useState(false);

  const active = item.path === pathname;

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    item?.items ?
      <>
        <ListItemButton onClick={handleClick} sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: 'body2',
          color: 'text.secondary',
          textTransform: 'capitalize',
          fontWeight: 'fontWeightMedium',
          ...((pathname === '/upload' || pathname === '/interview') && {
            color: 'primary.main',
            fontWeight: 'fontWeightSemiBold',
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
            },
          }),

        }}>
          <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
            {item.icon}
          </Box>

          <Box component="span">{item.title} </Box>
          <ListItemIcon>
          </ListItemIcon>
          <ListItemText />
          {open ? <Iconify
            icon="eva:arrow-ios-downward-outline"
            sx={{
              ...((pathname === '/upload' || pathname === '/interview') && {
                color: 'primary.main',
                fontWeight: 'fontWeightSemiBold',

              }), width: 16, height: 16
            }}
          /> : <Iconify
            icon="eva:arrow-ios-forward-outline"
            sx={{
              ...((pathname === '/upload' || pathname === '/interview') && {
                color: 'primary.main',
                fontWeight: 'fontWeightSemiBold',

              }), width: 16, height: 16
            }}
          />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" >
          {item?.items?.map((i) => {
            return <ListItemButton component={RouterLink}
              href={i.path}
              sx={{
                minHeight: 44,
                borderRadius: 0.75,
                typography: 'body2',
                color: 'text.secondary',
                textTransform: 'capitalize',
                fontWeight: 'fontWeightMedium',
                ...(i.path === pathname && {
                  color: 'primary.main',
                  fontWeight: 'fontWeightSemiBold',

                }),
                my: .5,
                ml: 4.5
              }}>
              <Box component="span" sx={{ width: 18, height: 18, mr: 1.5 }}>
                {i.icon}
              </Box>

              <Box component="span">{i.title} </Box>
            </ListItemButton>
          })}

        </Collapse>
      </>
      : <ListItemButton onClick={handleClick}
        component={RouterLink}
        href={item.path}
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: 'body2',
          color: 'text.secondary',
          textTransform: 'capitalize',
          fontWeight: 'fontWeightMedium',
          ...(active && {
            color: 'primary.main',
            fontWeight: 'fontWeightSemiBold',
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
            },
          }),
        }}
      >
        <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
          {item.icon}
        </Box>

        <Box component="span">{item.title} </Box>
      </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "src/components/Elements/BlogBox";
import FullButton from "src/components/Buttons/FullButton";
import HeaderImage from "../../assets/img/Pic1.webp";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg" style={{ paddingBottom: 50 }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Prepare smarter and perform better with Capstone Interviewer, your ultimate AI interview coach.</h1>
          </HeaderInfo>
          <div className="container " style={{ display: "flex", justifyContent: "center" }}>
            <p style={{display: 'flex',alignItems: 'center',padding: 40, fontSize:20}} >
              Capstone Interviewer is the best AI interview coach on the market, offering personalized feedback on facial sentiment, confidence, speaking quality, and optimal responses. Enhance your interview skills with expert-designed content and realistic simulations, ensuring you're prepared, confident, and ready to excel in any interview.
            </p>
            <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9, height: 400 }} />

          </div>
        </div>
      </div>
      {/* <div className="lightBg" style={{ padding: '50px 0' }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  text-align:center;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  padding-top: 40px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
import { useContext, useState } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { useRouter } from 'src/routes/hooks';
import { bgGradient } from 'src/theme/css';
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, googleProvider } from '../../config/firebase';
import { signInWithPopup } from 'firebase/auth';
import appContext from 'src/Context/AppContext';
import { authorizeMe, getByUsername, googleAuth, resetpassword, signin } from 'src/service/service';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function ResetPasswordView() {

  const theme = useTheme();
  const router = useRouter();
  let [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [user] = useAuthState(auth);
  const AppContext = useContext(appContext);
  const {
    register,
    handleSubmit, watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    AppContext?.setLoading(true)
    try {
      let res = await resetpassword({ password: data?.password }, searchParams.get("token"));
      if (res) {
        AppContext?.setLoading(false)
      }

    } catch (e) { console.error(e?.response?.data?.message); toast.error(e?.response?.data?.message); AppContext?.setLoading(false) }
  };


  const renderForm = (
    <form noValidate onSubmit={handleSubmit(onSubmit)} >
      <Stack spacing={3} >

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password', {
            required: true,
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/,
            },
          })}
        />
        {errors.password && errors.password.type === 'required' && <p className="text-danger " style={{ fontSize: 12 }}>Password is required.</p>}
        {errors.password && errors.password.type === 'pattern' && <p className="text-danger " style={{ fontSize: 12 }}>Password must be alphanumeric</p>}

        <TextField
          name="cpassword"
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('cpassword', {
            required: true, validate: (val) => {
              if (watch('password') != val) {
                return "Your passwords do no match";
              }
            },
          })}
        />
        {errors.cpassword?.type === 'required' && <p className="text-danger " style={{ fontSize: 12 }}>Confirm Password is required.</p>}
        {errors.cpassword && <p className="text-danger " style={{ fontSize: 12 }}>{errors.cpassword.message}</p>}


      </Stack>

      <LoadingButton sx={{ mt: 4 }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
      >
        Update Password
      </LoadingButton>
    </form>
  );

  return (
    <>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: 500, textAlign: "center" }}>Reset Password </Typography>

      {/* <Typography variant="body2" sx={{ mb: 4, fontWeight: 500, textAlign: "center" }}>Type the email associated with your Capstone account and we’ll send a link to reset your password </Typography> */}
      {renderForm}
    </>


  );
}

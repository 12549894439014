import React, { useEffect, useReducer, useState } from 'react'
import AppReducer from './AppReducer'
import AppContext from './AppContext'
import { SET_INTERNAL_LOADING, SET_IS_LOGGED_In, SET_LOADING, SET_LOADING_STATUS, SET_TOKEN, SET_USER } from './AppType'
import { authorizeMe, axios_instance, getuploadingStatus, me } from '../service/service'
import { toast } from 'react-toastify'

export const AppState = (props) => {
    let initialState = {
        user: undefined,
        isLoggedIn: null,
        token: "",
        loading: false,
        internalLoading: false,
        loadingstatusList: []
    }

    useEffect(() => {
        handleRefresh();
    }, [])

    const handleRefresh = async () => {
        if (localStorage.getItem('token')) {
            const value = localStorage.getItem('token');
            authorizeMe(value);
            callMe();
        }
        else {
            setIsLoggedIn(false);
        }
    }

    const callMe = async () => {
        setIsLoggedIn(true);
        try {
            var res = await me();
            setUser(res?.data);
        } catch (error) {
            setLoading(false)
            console.error("Error ", error);
        }
    }

    useEffect(() => {
        // Set up response interceptor for handling 401 errors
        axios_instance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    toast.error("Session Timeout,Please again Sign In!")
                    localStorage.clear();
                    location.href = "login";
                    setLoading(false)
                }
                return Promise.reject(error);
            }
        );
    })


    const [state, dispatch] = useReducer(AppReducer, initialState);

    const setUser = async (user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        const value = localStorage.getItem('token')
        if (value) setToken(value);
        dispatch({ type: SET_USER, payload: user });
    };

    const getLoadingStatus = async () => {
        var res = await getuploadingStatus();
        dispatch({ type: SET_LOADING_STATUS, payload: res?.data });
    }

    const setIsLoggedIn = (status) => {
        localStorage.setItem("isLoggedin", status);
        dispatch({ type: SET_IS_LOGGED_In, payload: status });
    }

    const setLoading = (loading) => {
        dispatch({ type: SET_LOADING, payload: loading });
    }
    const setInternalLoading = (internalLoading) => {
        dispatch({ type: SET_INTERNAL_LOADING, payload: internalLoading });
    }

    const setToken = async (token) => {
        authorizeMe(token);
        dispatch({ type: SET_TOKEN, payload: token });
    };

    return (
        <AppContext.Provider
            value={{
                user: state.user,
                isLoggedIn: state.isLoggedIn,
                token: state.token,
                loading: state.loading,
                internalLoading: state.internalLoading,
                loadingstatusList: state.loadingstatusList,
                handleRefresh,
                setIsLoggedIn,
                setUser,
                setToken,
                setLoading,
                setInternalLoading,
                getLoadingStatus,
                callMe
            }}
        >
            {props.children}
        </AppContext.Provider>
    )
}

import { Helmet } from 'react-helmet-async';

import { ResetPasswordView } from 'src/sections/resetpassword';


// ----------------------------------------------------------------------

export default function ResetPasswordPage() {

    return (
        <>
            <Helmet>
                <title> Reset Password | Capstone Interviewer </title>
            </Helmet>
            <ResetPasswordView />
        </>
    );
}

import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import appContext from 'src/Context/AppContext';
import Loader from 'src/utils/loader';
// components
//

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const AppContext = useContext(appContext);


  if (AppContext?.isLoggedIn) {
    return <Navigate to="/" />;
  }

  if (AppContext?.isLoggedIn === null) {
    return <Loader />;
  }

  return <> {children} </>;
}

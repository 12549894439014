import { useContext, useState } from 'react';

import Box from '@mui/material/Box';
import { auth } from './../../../config/firebase';
import { useRouter } from 'src/routes/hooks';
import appContext from 'src/Context/AppContext';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const router = useRouter();
  const AppContext = useContext(appContext);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    router.push('/login');
    auth.signOut();
    window.location.reload();
  };

  return (
    <>
      <Box display={'flex'} alignItems={'center'} >
        <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
          <SvgColor src={`/assets/icons/navbar/person.svg`} sx={{ width: 1, height: 1 }} />
        </Box>
        <Box component="span">{AppContext?.user?.name || "-"}  </Box>
      </Box>
    </>
  );
}

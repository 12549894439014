import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import appContext from "src/Context/AppContext";

export default function Loader({ uploading }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const AppContext = useContext(appContext);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % AppContext?.loadingstatusList.length);
        }, 10000);

        // Cleanup function to clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, [AppContext?.loadingstatusList]);

    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={"center"} sx={{ height: "90vh" }}>
            <section style={{ width: 320, textAlign: "center" }}>
                <div className="loaderss loader-1">
                    <div className="loader-outter"></div>
                    <div className="loader-inner"></div>
                    {window.location.hash === '#/upload' && uploading?.type && <div className="inner">{uploading?.percentage}%</div>}
                </div>
                {(window.location.hash === '#/interview' || window.location.hash === '#/upload') && uploading && < Typography variant="body1" color={'primary'} style={{ textAlign: "center" }} className="fade-in">{AppContext?.loadingstatusList[currentIndex]}</Typography>}

            </section>
        </Box >
    );
}

import { SET_INTERNAL_LOADING, SET_IS_LOGGED_In, SET_LOADING, SET_TOKEN, SET_USER, SET_LOADING_STATUS } from './AppType'

export default (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_USER:
            return {
                ...state,
                user: payload
            };

        case SET_IS_LOGGED_In:
            return {
                ...state,
                isLoggedIn: payload
            };

        case SET_TOKEN:
            return {
                ...state,
                token: payload
            };

        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case SET_INTERNAL_LOADING:
            return {
                ...state,
                internalLoading: payload
            };
        case SET_LOADING_STATUS:
            return {
                ...state,
                loadingstatusList: payload
            };
        default:
            return state;
    }
}
import React from "react";
import styled from "styled-components";
// Components
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";
import FullButton from "src/components/Buttons/FullButton";
import ServiceBox from "src/components/Elements/ServiceBox";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Iconify from "src/components/iconify";
import { Link } from "react-scroll";
import { useRouter } from "src/routes/hooks";
import HeaderImage from "../../assets/img/Pic4.webp";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Services() {
  const [value, setValue] = React.useState(0);
  const router = useRouter()
  const mediaQueryList = window.matchMedia("(max-width: 700px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div >
      <Wrapper id="about">

        <div className="lightBg" style={{ background: "#2f5190", position: "relative" }}>
          <div className="context">
            <div className="container">

              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                <div>
                  <Typography variant="h3" mb={5} color={'#fff'}>Experience Your AI Interview Coach</Typography>

                  {/* <video width={!mediaQueryList?.matches ? "600" : "auto"} controls src="https://www.youtube.com/watch?v=JEAWDE-rnN8" style={{ borderRadius: 15 }} >

                </video> */}
                  <p style={{ color: 'white' }}>
                    For us, enhancing educational opportunities and providing affordable and reliable access to high-quality medical school interview preparation is everything.
                    Driven by a strong commitment to Diversity, Equity, Inclusion, and Belonging, our team aims to level the playing field for all medical school applicants.
                    We are motivated to harness the power of AI and advanced technologies to create impactful, state-of-the-art solutions for medical school interview preparation.
                    Together, we can make a difference in shaping the future of healthcare by empowering the next generation of medical professionals!
                  </p>
                </div>
              </Box>
            </div>

          </div>
          <div className="area" >
            <ul className="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div >


        </div>

        {/* <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div> */}
        <div className="lightBg" style={{ padding: "60px 0" }}>


          {/* <div className="whiteBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">A few words about Capstone</h4>
                <h2 className="font40 extraBold">How we works</h2>
                <p className="font12">
                  <ul style={{ listStyleType: "revert" }}>
                    <li>
                      Install the Capstone desktop application and synchronize it. Capstone will provide coaching automatically during your important calls!
                    </li>
                    <li>
                      Yoodli offers real-time prompts to boost your confidence while you're on your call. Rest assured, it's simply analyzing your performance!
                    </li>
                    <li>

                      Track your progress using a dashboard that displays your statistics over time! Monitor your performance compared to suggested benchmarks to gauge your improvement!
                    </li>
                  </ul>
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                  <div style={{ width: "190px" }} onClick={() => router.push('/login')}>
                    <FullButton title="Get Started" />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <Link to="contact" spy={true} smooth={true} offset={-80}>
                      <FullButton title="Contact Us" border />
                    </Link>
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div> */}
          <div className="container tabsforWhy" >
            <HeaderInfo>
              <Typography variant="h3" mb={5} color={'#000'} textAlign={'center'}>Why Choose Capstone Interviewer?</Typography>

            </HeaderInfo>
            <ServiceBoxRow className="flex home-tabs" style={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ width: '100%', textAlign: "center" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Personalized Feedback on Facial Sentiment"  {...a11yProps(0)} />
                    <Tab label="Boost Your Confidence"  {...a11yProps(1)} />
                    <Tab label="Enhance Speaking Quality"{...a11yProps(2)} />
                    <Tab label="Optimal Responses"{...a11yProps(2)} />
                  </Tabs>
                </Box>
                <div className="background-4">
                  <CustomTabPanel value={value} index={0}>
                    <Grid container alignItems={'center'}>

                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ borderRadius: 4, color: "#fff" }}>
                          <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9, height: 300 }} />
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={5} py={2} fontWeight={500}>
                          Understand how your expressions are perceived and make improvements to convey the right emotions during your interview.</Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <Grid container alignItems={'center'}>
                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ background: "#f2f2f2", borderRadius: 4, padding: 20 }}>
                          <p>Image</p>
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={5} py={2} fontWeight={500}>
                          Receive constructive feedback to help you speak confidently and make a lasting impression on your interviewers.
                        </Typography>
                      </Grid>

                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <Grid container alignItems={'center'}>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={5} py={2} fontWeight={500}>
                          Get insights on your speaking pace, clarity, and tone, ensuring your communication is clear and effective.
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ background: "#f2f2f2", borderRadius: 4, padding: 20 }}>
                          <p>Image</p>
                        </Box>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <Grid container alignItems={'center'}>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={5} py={2} fontWeight={500}>
                          Learn how to structure and deliver the best answers to common medical school interview questions, increasing your chances of success.
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ background: "#f2f2f2", borderRadius: 4, padding: 20 }}>
                          <p>Image</p>
                        </Box>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                </div>
              </Box>

            </ServiceBoxRow>
          </div>


        </div>

      </Wrapper>
      <div className="container " style={{ padding: "60px 20px 0px 20px", background: 'white' }}>
        <HeaderInfo>
          <Typography variant="h3" color={'#000'} textAlign={'center'}>Our Product</Typography>
          <br />
          <br />
          <p>  Welcome to Capstone Interviewer, your ultimate solution for medical school interview preparation. Our innovative tool is designed to provide you with a comprehensive and immersive interview practice experience, ensuring you are well-prepared to excel in your medical school interviews.</p>
        </HeaderInfo>
        <ServiceBoxRow className="flex home-tabs" style={{ display: "flex", justifyContent: "center", padding: '0px 100px' }}>
          <ServiceBoxWrapper style={{ justifyContent: 'center' }}>
            <ServiceBox
              icon="roller"
              title="Tailored Interview Simulation"
              subtitle="Experience realistic simulations of medical school interviews with a large pool of commonly asked questions."
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="monitor"
              title="Personalized Feedback"
              subtitle="Receive crucial feedback on your verbal and non-verbal communication skills, enhancing your overall interview performance"
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="browser"
              title="Advanced Analysis"
              subtitle="Benefit from advanced algorithms that analyze facial expressions, sentiments, and speech quality, providing real-time feedback for improvement."
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox icon="printer" title="Optimal Answer Comparison" subtitle="Compare your responses with optimal answers through side-by-side comparisons, highlighting strengths and suggesting improvements." />
          </ServiceBoxWrapper>

        </ServiceBoxRow>
        {/* Get Started:

        Sign Up: Create an account to access our comprehensive interview preparation tool.
        Practice Interviews: Engage in tailored interview simulations with commonly asked questions.
        Receive Feedback: Gain personalized feedback on your performance, including verbal and non-verbal skills.
        Optimize Responses: Compare your answers with optimal responses and refine your interview skills.
        Excel in Interviews: Approach your medical school interviews with confidence and poise, ready to make a positive impression.
        Join Capstone Interviewer today and take the first step towards achieving your dream of becoming a physician. Enhance your interview preparation with our state-of-the-art AI-driven tool and stand out in the competitive landscape of medical school admissions. */}

      </div>
    </div >
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 30%;
    height: 30%;
  }
`;
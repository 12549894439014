import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './app';
import { AppState } from './Context/AppState';
import { ToastContainer } from 'react-toastify';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <ToastContainer position="top-right" autoClose={5000} />
    <AppState>
      <HashRouter>
        <Suspense>
          <App />
        </Suspense>
      </HashRouter>
    </AppState>
  </HelmetProvider>
);

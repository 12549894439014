import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import appContext from 'src/Context/AppContext';
import Loader from 'src/utils/loader';
import LoginPage from 'src/pages/public/login';
import Landing from 'src/pages/public/Landing';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {

  const AppContext = useContext(appContext);
  console.log(AppContext?.isLoggedIn, AppContext?.user);

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (AppContext?.isLoggedIn === null && AppContext?.user === undefined) {
    return <Loader />;
  }

  if (!AppContext?.isLoggedIn) {

    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Landing />;
  }

  if (requestedLocation && pathname !== requestedLocation) {

    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}

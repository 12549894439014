// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB5AcGCTwEnytZ5yEDmU2WPB_xt59ZpIqg",
    authDomain: "capstone-interviewer.firebaseapp.com",
    projectId: "capstone-interviewer",
    storageBucket: "capstone-interviewer.appspot.com",
    messagingSenderId: "825099490508",
    appId: "1:825099490508:web:b71c6ba7d3771b10979e69",
    measurementId: "G-ZW3R65JH04"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
